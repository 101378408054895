<script
    lang="ts"
    setup
>
    import type { KBItem } from '~/ts/types/knowledge-base'
    import { useKnowledgeBaseStore } from '~/stores/knowledge-base'

    const knowledgeBaseStore = useKnowledgeBaseStore()

    const articleAncestors = computed<KBItem[]>(() => {
        return knowledgeBaseStore.findItemAncestors(
            knowledgeBaseStore.flattenTree.find(v => v._id === knowledgeBaseStore.currentArticle._id)
        )
    })

    const breadcrumbs = computed<string[]>(() => [
        ...articleAncestors.value.map(v => v.title),
        knowledgeBaseStore.currentArticle.title
    ])
</script>

<template>
    <KnowledgeBaseContentBodyWidgetKBLayout>
        <template #header-center__scroll-content="{ show }">
            <div
                v-if="show"
                :class="$style['header-center__scroll-content']"
                :title="knowledgeBaseStore.currentArticle.title"
            >
                {{ knowledgeBaseStore.currentArticle.title }}
            </div>
        </template>

        <template #default="{ scrollHandler }">
            <KnowledgeBaseContentBodyWidgetPage :class="$style['knowledge-base-article']">
                <KnowledgeBaseContentBodyWidgetBreadcrumbs :items="breadcrumbs"/>

                <KnowledgeBaseContentBodyWidgetTextEditor
                    :class="$style['knowledge-base-article__editor']"
                    :title="knowledgeBaseStore.currentArticle.title"
                    :content="knowledgeBaseStore.currentArticle.content"
                    style="pointer-events: initial"
                    @scroll.passive="scrollHandler"
                >
                    <!--                    <template #after-content>-->
                    <!--                        <div :class="$style['knowledge-base-article__latest-update']">-->
                    <!--                            {{ $t('latest-update') }}: Датачас-->
                    <!--                        </div>-->

                    <!--                        <AppDivider style="margin: 16px 0"/>-->

                    <!--                        <KnowledgeBaseArticleViewFeedback/>-->
                    <!--                    </template>-->
                </KnowledgeBaseContentBodyWidgetTextEditor>
            </KnowledgeBaseContentBodyWidgetPage>
        </template>
    </KnowledgeBaseContentBodyWidgetKBLayout>
</template>

<style
    lang="sass"
    module
    scoped
>
    .header-center__scroll-content
        display: -webkit-box
        -webkit-box-orient: vertical
        -webkit-line-clamp: 2
        overflow: hidden
        flex: 1
        padding: 0 40px 0 76px
        font-size: 16px
        line-height: 130%

    .knowledge-base-article
        gap: 16px
        margin-top: 8px
        padding: 0 16px

        &__editor
            display: flex
            flex: 1
            flex-direction: column

        &__latest-update
            font-size: 12px
            font-weight: 400
            line-height: 130%
            color: #8a8f9e
</style>
