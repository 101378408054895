<script
    lang="ts"
    setup
>
    import { useKnowledgeBaseStore } from '~/stores/knowledge-base'

    const knowledgeBaseStore = useKnowledgeBaseStore()

    const expanded = computed<boolean>(() => knowledgeBaseStore.viewMode === 'expanded')
</script>

<template>
    <div :class="$style['layout']">
        <div
            key="header"
            :class="$style['layout__header']"
        >
            <div :class="$style['layout__header__side-left']">
                <AppButtonIcon
                    key="back"
                    small
                >
                    <AppIconArrowLeft size="20"/>
                </AppButtonIcon>

                <AppButtonIcon
                    key="expand-widget"
                    small
                >
                    <AppIconFullscreen
                        v-if="!expanded"
                        key="on"
                        size="20"
                    />

                    <AppIconFullscreenExit
                        v-else
                        key="off"
                        size="20"
                    />
                </AppButtonIcon>
            </div>

            <div
                v-if="$slots['header-center']"
                key="center"
                :class="$style['layout__header__center']"
            >
                <slot name="header-center"/>
            </div>

            <div :class="$style['layout__header__side-right']">
                <AppButtonIcon
                    small
                >
                    <AppIconClose size="20"/>
                </AppButtonIcon>
            </div>

            <!--            <TheNotifications :class="$style['layout__header__notifications']"/>-->
        </div>

        <div
            key="body"
            :class="$style['layout__body']"
        >
            <slot/>
        </div>

        <!--        <div-->
        <!--            v-if="widgetConfig.is_enabled_branding"-->
        <!--            key="footer"-->
        <!--            :class="$style['layout__footer']"-->
        <!--        >-->
        <!--            <AppBranding/>-->
        <!--        </div>-->
    </div>
</template>

<style
    lang="sass"
    module
    scoped
>
    .layout
        display: flex
        flex: 1 1 auto
        flex-direction: column
        min-height: 100%
        max-width: 100%
        backface-visibility: hidden

        &__header
            pointer-events: none
            position: relative
            display: flex
            align-items: center
            justify-content: space-between
            max-height: 75px
            min-height: 75px
            width: 100%
            padding: 0 10px

            &__center
                display: flex
                align-items: center
                justify-content: center
                width: 100%
                height: 100%

            &__side
                &-left,
                &-right
                    opacity: 0.6
                    position: absolute
                    display: flex

                &-left
                    left: 10px

                &-right
                    right: 10px

            &__notifications
                position: absolute
                z-index: 1
                top: 100%
                left: 0

        &__body
            overflow: hidden
            position: relative
            display: flex
            flex: 1
            flex-direction: column
            justify-content: flex-start
            max-width: 100%
            max-height: 100%

        &__footer
            display: flex
            align-items: center
            justify-content: center
            width: 100%
            height: 42px
</style>
