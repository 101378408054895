<script
    setup
    lang="ts"
>
    const showHeaderScrollContent = ref<boolean>()

    const headerContentSwitcher = {
        started: false,
        timer: null,
        handler({ target }) {
            const self = headerContentSwitcher

            if (!self.started) {
                showHeaderScrollContent.value = true
            } else if (!target.scrollTop) {
                showHeaderScrollContent.value = false
            }

            self.started = true

            clearTimeout(self.timer)

            self.timer = setTimeout(() => {
                self.started = false
                self.timer = null
            }, 200)
        }
    }
</script>

<template>
    <KnowledgeBaseContentBodyWidgetLayout>
        <template #header-center>
            <slot
                name="header-center__scroll-content"
                :show="showHeaderScrollContent"
            >
                <div
                    key="kb-title"
                    :class="$style['knowledge-base-title']"
                >
                    {{ $t('knowledge-base') }}
                </div>
            </slot>
        </template>

        <slot :scroll-handler="headerContentSwitcher.handler"/>
    </KnowledgeBaseContentBodyWidgetLayout>
</template>

<style
    lang="sass"
    module
    scoped
>
    .knowledge-base-title
        display: flex
        align-items: center
        text-align: center
        font-style: normal
        font-size: 20px
        font-weight: 500
        line-height: 24px
</style>
