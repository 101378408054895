<script
    lang="ts"
    setup
>
    import { Editor } from '@tiptap/vue-3'

    type Props = {
        editor: Editor
    }

    const props = defineProps<Props>()

    const textMenuOptions = [
        {
            value: 'title',
            extensionName: 'heading',
            action: 'setHeading',
            actionArg: { level: 1 }
        },
        {
            value: 'subtitle',
            extensionName: 'heading',
            action: 'setHeading',
            actionArg: { level: 2 }
        },
        {
            value: 'text',
            extensionName: 'paragraph',
            action: 'setParagraph'
        },
        {
            value: 'quote',
            extensionName: 'blockquote',
            action: 'toggleBlockquote'
        },
        {
            value: 'code',
            extensionName: 'codeBlock',
            action: 'toggleCodeBlock'
        }
    ]
</script>

<template>
    <AppDropdown
        width="auto"
        content-class="flex flex-col gap-1"
    >
        <template #activator="{ toggle, active }">
            <button
                type="button"
                :class="[
                    'app-text-editor-menu__button',
                    { 'app-text-editor-menu__button--active': active }
                ]"
                style="border-right: 1px solid #e3e5eb"
                @click="toggle"
            >
                <span style="font-size: 14px">
                    {{ $t('text') }}
                </span>

                <AppIconChevronDown
                    size="16"
                    :rotate="active"
                    style="margin-left: 2px"
                />
            </button>
        </template>

        <template #default="{ close }">
            <AppDropdownItem
                v-for="{ value, extensionName, action, actionArg } in textMenuOptions"
                :key="value"
                :active="props.editor.isActive(extensionName, actionArg)"
                style="font-weight: 500; color: #8a8f9e"
                @click="props.editor.chain().focus()[action](actionArg).run(); close()"
            >
                {{ $t(value) }}
            </AppDropdownItem>
        </template>
    </AppDropdown>
</template>
