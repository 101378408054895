<script
    lang="ts"
    setup
>
    import { useKnowledgeBaseStore } from '~/stores/knowledge-base'

    const knowledgeBaseStore = useKnowledgeBaseStore()
</script>

<template>
    <div
        class="
            flex
            items-center
            justify-around
            gap-2
            px-2
            max-h-[var(--kb-header-height)]
            min-h-[var(--kb-header-height)]
        "
    >
        <div class="flex tablet:hidden items-center p-1">
            <AppButtonIcon
                small
                @click="knowledgeBaseStore.setArticle()"
            >
                <AppIconArrowLeft size="20"/>
            </AppButtonIcon>
        </div>

        <KnowledgeBaseContentHeaderLanguage/>

        <KnowledgeBaseContentHeaderContentMode/>

        <KnowledgeBaseContentHeaderViewMode/>
    </div>
</template>
