<script
    lang="ts"
    setup
>
    import type { AppInfoSectionItem } from '~/ts/types/app'
    import { useKnowledgeBaseStore } from '~/stores/knowledge-base'
    import { useUserStore } from '~/stores/user'

    const analyticsTooltipRef = ref<ReturnType<typeof defineComponent> | undefined>()

    const knowledgeBaseStore = useKnowledgeBaseStore()
    const userStore = useUserStore()

    const creationDate = useFormatDate(knowledgeBaseStore.currentArticle.created_at, {
        watchValue: () => knowledgeBaseStore.currentArticle.created_at
    })

    const lastSave = useFormatDate(knowledgeBaseStore.currentArticle.updated_at, {
        format: null,
        autoUpdate: true,
        watchValue: () => knowledgeBaseStore.currentArticle.updated_at
    })

    const sectionDefaults = useAppCookie<{ generalInfo: boolean, analytics: boolean }>('kb:info-sections', {
        default: () => ({
            generalInfo: true,
            analytics: true
        })
    })

    const { t } = useLang()

    const infoItems = computed<AppInfoSectionItem[]>(() => [
        {
            name: 'analytics',
            title: t('article-analytics'),
            values: [],
            defaultValue: sectionDefaults.value.analytics,
            onToggled: value => (sectionDefaults.value = { ...sectionDefaults.value, analytics: value })
        },
        {
            name: 'general-information',
            title: t('general-information'),
            values: [
                {
                    label: t('creation-date'),
                    value: creationDate.value
                },
                {
                    label: t('last-save'),
                    value: lastSave.value
                }
            ],
            defaultValue: sectionDefaults.value.generalInfo,
            onToggled: value => (sectionDefaults.value = { ...sectionDefaults.value, generalInfo: value })
        }
    ])
</script>

<template>
    <AppInfoSection
        :items="infoItems"
        class="flex flex-col gap-4 leading-[120%]"
    >
        <template
            v-if="!userStore.activeSubscription"
            #item-before-body:analytics
        >
            <AppProRestrictionBlock
                class="
                    z-[1]
                    absolute
                    top-0
                    left-0
                    h-full
                    w-full
                "
            >
                <template #hint>
                    {{ $t('available-in-pro-device-details-hint') }}
                </template>
            </AppProRestrictionBlock>
        </template>

        <template #item-body:analytics>
            <KnowledgeBaseSidebarInfoAnalytics/>
        </template>
    </AppInfoSection>
</template>
