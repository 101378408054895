<script
    lang="ts"
    setup
>
    import { EditorContent, useEditor } from '@tiptap/vue-3'
    import { Document } from '@tiptap/extension-document'
    import { Text } from '@tiptap/extension-text'
    import { Paragraph } from '@tiptap/extension-paragraph'
    import { Bold } from '@tiptap/extension-bold'
    import { Italic } from '@tiptap/extension-italic'
    import { Strike } from '@tiptap/extension-strike'
    import { Underline } from '@tiptap/extension-underline'
    import { Heading } from '@tiptap/extension-heading'
    import { HardBreak } from '@tiptap/extension-hard-break'
    import { Link } from '@tiptap/extension-link'
    import { Image } from '@tiptap/extension-image'
    import { ListItem } from '@tiptap/extension-list-item'
    import { OrderedList } from '@tiptap/extension-ordered-list'
    import { BulletList } from '@tiptap/extension-bullet-list'
    import { Blockquote } from '@tiptap/extension-blockquote'
    import { CodeBlock } from '@tiptap/extension-code-block'
    import { Table } from '@tiptap/extension-table'
    import { TableCell } from '@tiptap/extension-table-cell'
    import { TableHeader } from '@tiptap/extension-table-header'
    import { TableRow } from '@tiptap/extension-table-row'

    type Props = {
        title: string
        content: string
    }

    const props = defineProps<Props>()

    const editorRef = ref<HTMLDivElement | undefined>()

    const { arrivedTop } = useScroll(editorRef)

    const editor = useEditor({
        content: props.content,
        editable: false,
        editorProps: {
            attributes: {
                spellcheck: 'false'
            }
        },
        extensions: [
            Document,
            Text,
            Paragraph,
            Bold,
            Italic,
            Strike,
            Underline,
            Heading.configure({ levels: [ 1, 2 ] }),
            HardBreak,
            Link,
            Image.configure({
                allowBase64: true
            }),
            ListItem,
            OrderedList,
            BulletList,
            Blockquote,
            CodeBlock,
            Table.configure({
                resizable: false,
                allowTableNodeSelection: false
            }),
            TableCell,
            TableHeader,
            TableRow
        ]
    })

    onBeforeUnmount(() => {
        editor.value.destroy()
    })
</script>

<template>
    <div
        ref="editorRef"
        :class="[ $style['editor'], 'widget-text-editor' ]"
    >
        <Transition name="fade-transition">
            <KnowledgeBaseContentBodyWidgetScrollGradient
                v-if="!arrivedTop"
                key="gradient"
                style="top: 44px !important"
            />
        </Transition>

        <div :class="$style['editor__title']">
            {{ props.title }}
        </div>

        <EditorContent
            :editor="editor"
            :class="$style['editor__content']"
        />

        <slot name="after-content"/>
    </div>
</template>

<style
    lang="sass"
    module
>
    .editor
        overflow-y: auto
        background: #fff

        &,
        &__content
            width: 100%

        &__title
            min-width: 29px
            width: 100%
            font-style: normal
            font-size: 24px
            font-weight: 500
            line-height: 29px
            background: none
            color: #000

        p
            font-size: 16px
            font-weight: 400
            line-height: 130%

        h1
            font-size: 24px
            font-weight: 500
            line-height: 29px

        h2
            font-size: 16px
            font-weight: 500
            line-height: 19px

        img
            max-width: 100%
            height: auto
</style>

<style lang="sass">
    .widget-text-editor
        .ProseMirror
            outline: none
            padding: 16px 0

            > * + *
                margin: 0

            ul,
            ol
                padding: 0 24px

            blockquote
                padding-left: 1rem
                border-left: 3px solid rgba(#0D0D0D, 0.1)

            pre
                padding: 0.75rem 1rem
                font-family: 'JetBrainsMono', monospace
                background: #0d0d0d
                color: #fff
                border-radius: 0.5rem

            code
                padding: 0
                font-size: 0.8rem
                background: none
                color: inherit

            a
                outline: none
                text-decoration: underline
                color: #000
                transition: color var(--transition-default-duration-with-ease)
                will-change: color
                cursor: pointer

                &:hover,
                &:active
                    color: #8a8f9e

            table
                overflow: hidden
                border-collapse: collapse
                table-layout: fixed
                width: 100%
                margin: 0

                td,
                th
                    box-sizing: border-box
                    position: relative
                    min-width: 1em
                    vertical-align: top
                    padding: 3px 5px
                    border: 2px solid #ced4da

                    > *
                        margin-bottom: 0

                th
                    font-weight: bold
                    text-align: left
                    background-color: #f1f3f5

                p
                    margin: 0

            .tableWrapper
                padding: 1rem 0
                overflow-x: auto

        .ProseMirror .is-empty::before
            content: attr(data-placeholder)
            pointer-events: none
            float: left
            height: 0
            color: #aaadb8
</style>
