<script
    lang="ts"
    setup
>
    import { useKnowledgeBaseStore } from '~/stores/knowledge-base'

    definePageMeta({
        middleware: 'auth'
    })

    const { window } = getClientContext()
    const { maxTablet, tablet, desktop, onMaxTabletChange, onDesktopChange } = useWindowSize()
    const { language, t, isSupportedLanguage } = useLang()
    const { currentRoute, beforeEach } = useRouter()
    const isMounted = useMounted()

    const knowledgeBaseStore = useKnowledgeBaseStore()

    const showSidebar = ref<boolean>(false)
    const transitionEnabled = ref<boolean>(false)

    await knowledgeBaseStore.fillState(currentRoute.value)

    onDesktopChange(v => (showSidebar.value = v))

    knowledgeBaseStore.contentLanguage = isSupportedLanguage(currentRoute.value.query.lang as any)
        ? currentRoute.value.query.lang as any
        : language.value

    const pageBeforeLeave = (event: BeforeUnloadEvent | CloseEvent): void => {
        if (knowledgeBaseStore.currentArticle?.isDirty()) {
            event.preventDefault()

            event.returnValue = ''
        }
    }

    onEvent(window, 'beforeunload', pageBeforeLeave, { capture: true })
    onEvent(window, 'onclose', pageBeforeLeave, { capture: true })

    const unregisterRouteGuard = beforeEach(async () => {
        return await knowledgeBaseStore.handleArticleChangesBeforeClose({
            titleText: t('knowledge-base-before-page-leave-confirm-title'),
            confirmText: t('go-to')
        })
    })

    const showNavigation = computed<boolean>(() => {
        return tablet.value || !knowledgeBaseStore.currentArticle
    })

    const navigationTransitionName = computed<string>(() => {
        if (!transitionEnabled.value) {
            return
        }

        return tablet.value ? 'knowledge-base-navigation-transition' : 'slide-x-left-transition'
    })

    const contentTransitionName = computed<string | undefined>(() => {
        if (!transitionEnabled.value) {
            return
        }

        return tablet.value ? 'fade-transition' : undefined
    })

    const sidebarTransitionName = computed<string | undefined>(() => {
        if (!transitionEnabled.value) {
            return
        }

        return desktop.value ? 'fade-transition' : 'slide-x-right-transition'
    })

    onMounted(() => {
        if (tablet.value) {
            knowledgeBaseStore.setFirstOrNewArticle(currentRoute.value)
        }

        showSidebar.value = desktop.value

        nextTick(() => {
            transitionEnabled.value = true
        })

        onMaxTabletChange(v => !v && knowledgeBaseStore.setFirstOrNewArticle(currentRoute.value))
    })
    onBeforeUnmount(() => unregisterRouteGuard())
    onUnmounted(() => knowledgeBaseStore.$reset())
</script>

<template>
    <AppPage>
        <Title>
            {{ $t('knowledge-base') }}
        </Title>

        <div class="relative overflow-hidden flex h-[inherit] bg-[#f6f7f8]">
            <Transition :name="navigationTransitionName">
                <KnowledgeBaseNavigation
                    v-if="isMounted && (maxTablet || knowledgeBaseStore.showNavigation)"
                    v-show="showNavigation"
                    key="navigation"
                />
            </Transition>

            <Transition :name="contentTransitionName">
                <KnowledgeBaseContent
                    v-if="isMounted && knowledgeBaseStore.showContentAndSidebar"
                    key="content"
                >
                    <template #after-content>
                        <Transition name="scale-transition">
                            <KnowledgeBaseSidebarControl
                                v-if="isMounted"
                                v-show="knowledgeBaseStore.currentArticle && !showSidebar"
                                key="control"
                                v-model:showSidebar="showSidebar"
                            />
                        </Transition>
                    </template>
                </KnowledgeBaseContent>
            </Transition>

            <Transition :name="sidebarTransitionName">
                <KnowledgeBaseSidebar
                    v-if="isMounted && knowledgeBaseStore.showContentAndSidebar"
                    v-show="showSidebar"
                    key="sidebar"
                    v-model:showSidebar="showSidebar"
                />
            </Transition>
        </div>
    </AppPage>
</template>

<style lang="sass">
    .knowledge-base-navigation-transition
        &-enter-from,
        &-leave-to
            min-width: 0 !important
            max-width: 0 !important

        &-enter-active,
        &-leave-active
            pointer-events: none
            overflow: hidden
            transition: max-width var(--transition-default-duration-with-ease), min-width var(--transition-default-duration-with-ease)

        &-enter-to,
        &-leave-from
            min-width: 350px !important
            max-width: 350px !important
</style>
