<script
    lang="ts"
    setup
>
    import { useKnowledgeBaseStore } from '~/stores/knowledge-base'

    type Props = {
        showSidebar: boolean
    }

    type Emit = {
        (event: 'update:showSidebar', value: boolean): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()

    const sidebarRef = ref<HTMLDivElement>()

    const showSidebar = useSyncProp(props, 'showSidebar', emit)

    const knowledgeBaseStore = useKnowledgeBaseStore()

    const { desktop } = useWindowSize()

    onMounted(() => {
        onClickOutside(sidebarRef, () => {
            if (!desktop.value) {
                showSidebar.value = false
            }
        })
    })
</script>

<template>
    <div
        ref="sidebarRef"
        class="
            max-desktop:z-[10]
            max-desktop:absolute
            max-desktop:top-0
            max-tablet:left-0
            tablet:max-desktop:right-0
            overflow-auto
            flex
            flex-col
            gap-4
            max-w-[350px]
            min-w-[350px]
            max-tablet:max-w-none
            max-tablet:min-w-full
            max-tablet:w-full
            h-full
            py-6
            px-4
            max-desktop:pt-2
            bg-white
            tablet:max-desktop:shadow-lg
        "
    >
        <div class="desktop:hidden flex items-center pt-2">
            <AppButtonIcon
                small
                @click="showSidebar = false"
            >
                <AppIconArrowLeft size="20"/>
            </AppButtonIcon>
        </div>

        <KnowledgeBaseSidebarForm/>

        <Transition name="fade-transition">
            <KnowledgeBaseSidebarInfo v-if="!knowledgeBaseStore.currentArticle?.new"/>
        </Transition>
    </div>
</template>
