<script
    lang="ts"
    setup
>
    import type { AppSegmentControlItem } from '~/ts/types/app'
    import { useKnowledgeBaseStore } from '~/stores/knowledge-base'

    const knowledgeBaseStore = useKnowledgeBaseStore()

    const { t } = useLang()
    const { maxTablet } = useWindowSize()

    const segments: AppSegmentControlItem[] = [
        {
            value: 'normal',
            text: 'normal',
            tooltipText: t('kb-view-mode-1'),
            tooltipArgs: {
                direction: 'down'
            }
        },
        {
            value: 'expanded',
            text: 'expanded',
            tooltipText: t('kb-view-mode-2'),
            tooltipArgs: {
                direction: 'down'
            }
        }
    ]
</script>

<template>
    <AppSegmentControl
        v-model="knowledgeBaseStore.viewMode"
        :items="segments"
        :disable-tooltips="maxTablet"
        item-width="auto"
        item-class="flex-1"
        class="max-tablet:!hidden basis-1/3 max-w-[140px]"
    >
        <template #item:normal="{ item }">
            <AppIconWidgetView
                size="16"
                :color="knowledgeBaseStore.viewMode === item.value ? '#000' : undefined"
            />
        </template>

        <template #item:expanded="{ item }">
            <AppIconFullSizeView
                size="16"
                :color="knowledgeBaseStore.viewMode === item.value ? '#000' : undefined"
            />
        </template>
    </AppSegmentControl>
</template>
