<script
    lang="ts"
    setup
>
    import { useKnowledgeBaseStore } from '~/stores/knowledge-base'

    const WIDGET_MAX_WIDTH = 680
    const WIDGET_MAX_HEIGHT = 800

    const WIDGET_DEFAULT_WIDTH = 376
    const WIDGET_DEFAULT_HEIGHT = 700

    const WIDGET_SAFE_AREA = 16
    const WIDGET_INDENT = 24 - WIDGET_SAFE_AREA

    const knowledgeBaseStore = useKnowledgeBaseStore()

    const expanded = computed<boolean>(() => knowledgeBaseStore.viewMode === 'expanded')

    // Віджет відкривається тільки на клієнті, тому не буде проблем з відсутність window
    const { height } = useWindowSize()

    const computedHeight = height.value - WIDGET_INDENT
    const maxWidthValue = ref<string>(`min(${ WIDGET_MAX_WIDTH }px, 100vw)`)
    const maxHeightValue = ref<string>((computedHeight > WIDGET_MAX_HEIGHT ? WIDGET_MAX_HEIGHT : computedHeight) + 'px')

    const sizeValues = computed<{ width: string, height: string }>(() => {
        return expanded.value
            ? {
                width: maxWidthValue.value,
                height: maxHeightValue.value
            }
            : {
                width: WIDGET_DEFAULT_WIDTH + 'px',
                height: WIDGET_DEFAULT_HEIGHT + 'px'
            }
    })
</script>

<template>
    <div :class="[ $style['widget'], 'max-tablet:!w-full' ]">
        <KnowledgeBaseContentBodyWidgetView/>
    </div>
</template>

<style
    lang="sass"
    module
    scoped
>
    .widget
        display: flex
        flex-direction: column
        max-width: v-bind('maxWidthValue')
        max-height: 100%
        width: v-bind('sizeValues.width')
        height: 100%
        background: #fff
        border-radius: 16px 16px 0 0
        box-shadow: 0 6px 14px rgba(0, 0, 0, 0.12)
        transition: width var(--transition-default-duration-with-ease), height var(--transition-default-duration-with-ease)
        will-change: width, height
</style>
