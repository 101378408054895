<template>
    <AppIcon v-bind="$props">
        <circle
            cx="12"
            cy="12"
            r="9"
            fill="#4CC153"
            fill-opacity="0.3"
        />

        <circle
            cx="12"
            cy="12"
            r="5"
            fill="#4CC153"
        />
    </AppIcon>
</template>
