<script
    lang="ts"
    setup
>
    import { Editor } from '@tiptap/vue-3'

    type Props = {
        editor: Editor
    }

    const props = defineProps<Props>()

    const textStyleOptions = [
        {
            value: 'bold',
            action: 'toggleBold',
            iconComponent: resolveComponent('AppIconBold')
        },
        {
            value: 'italic',
            action: 'toggleItalic',
            iconComponent: resolveComponent('AppIconItalic')
        },
        {
            value: 'underline',
            action: 'toggleUnderline',
            iconComponent: resolveComponent('AppIconUnderline')
        },
        {
            value: 'strike',
            action: 'toggleStrike',
            iconComponent: resolveComponent('AppIconCrossedOut')
        }
    ]
</script>

<template>
    <button
        v-for="{ value, action, iconComponent } in textStyleOptions"
        :key="value"
        type="button"
        :class="[
            'app-text-editor-menu__button',
            { 'app-text-editor-menu__button--active': props.editor.isActive(value) }
        ]"
        @click="props.editor.chain().focus()[action]().run()"
    >
        <component
            :is="iconComponent"
            size="16"
        />
    </button>
</template>
