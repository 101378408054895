<template>
    <div :class="$style['scroll-gradient']"></div>
</template>

<style
    lang="sass"
    module
    scoped
>
    .scroll-gradient
        pointer-events: none
        position: absolute
        width: 100%
        height: 50px
        top: 0
        left: 0
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0) 100%)
</style>
