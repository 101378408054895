import type { RouteLocationNormalizedLoaded } from 'vue-router'
import { defineStore } from 'pinia'
import { useUserStore } from '~/stores/user'
import type * as knowledgeBaseTypes from '~/ts/types/knowledge-base'
import type { Language } from '~/ts/types/language'
import type { Operator } from '~/ts/types/operator'
import type { FormSelectOption } from '~/ts/types/form'
import type { Optional } from '~/ts/types/common'
import type { AppConfirmContext } from '~/ts/types/app'
import { KBItemTypeEnum, KBItemStatusEnum } from '~/ts/enums/knowledge-base'
import flatTree from '~/helpers/knowledge-base/flatTree'

type KnowledgeBaseState = {
    tree: knowledgeBaseTypes.KBTreeItem
    currentArticle?: Optional<knowledgeBaseTypes.KBArticle, '_id' | 'type' | 'user_id' | 'views' | 'likes' | 'dislikes' | 'created_at' | 'updated_at'>
    contentLanguage: Language
    viewMode: knowledgeBaseTypes.KBViewMode
    contentMode: knowledgeBaseTypes.KBContentMode
    authors: Operator[]
    search: string
}

export const useKnowledgeBaseStore = defineStore('knowledgeBaseStore', {
    state: (): KnowledgeBaseState => ({
        tree: undefined,
        currentArticle: undefined,
        contentLanguage: undefined,
        viewMode: 'normal',
        contentMode: 'normal',
        authors: [],
        search: ''
    }),
    getters: {
        showNavigation: (state: KnowledgeBaseState): boolean => state.viewMode === 'normal',
        showContentAndSidebar: (state: KnowledgeBaseState): boolean => !!state.currentArticle,
        allArticles: (state: KnowledgeBaseState): knowledgeBaseTypes.KBArticle[] => {
            const articles = [] as knowledgeBaseTypes.KBArticle[]

            const articleSearcher = (children) => {
                for (const child of children) {
                    if (child.type === KBItemTypeEnum.Article) {
                        articles.push(child)

                        continue
                    }

                    articleSearcher(child.children || [])
                }
            }

            articleSearcher(state.tree?.children || [])

            return articles
        },
        statusOptions: (): FormSelectOption[] => {
            const { t } = useLang()

            return [
                { value: KBItemStatusEnum.Unpublished, text: t('unpublished') },
                { value: KBItemStatusEnum.Published, text: t('published') }
            ]
        },
        authorOptions: (state: KnowledgeBaseState): FormSelectOption[] => {
            const authorOptions: FormSelectOption[] = []

            for (const index in state.authors) {
                const value = state.authors[index]

                authorOptions.push({
                    _extra: {
                        searchValue: (value.user.name || '') + value.user.email,
                        ...value.user,
                        enabled: !!value.enabled
                    },
                    value: value.id,
                    text: value.user.email
                })
            }

            return authorOptions.sort((a, b) => {
                return b._extra.enabled - a._extra.enabled
            })
        },
        flattenTree(): knowledgeBaseTypes.KBItem[] {
            return flatTree(this.tree.children)
        }
    },
    actions: {
        async fillState(route: RouteLocationNormalizedLoaded): Promise<void> {
            if (this.tree) {
                return
            }

            const { language, isSupportedLanguage } = useLang()

            this.contentLanguage = isSupportedLanguage(route.query.lang as any)
                ? route.query.lang as Language
                : language.value

            await Promise.all([
                this.refreshTree(),
                this.refreshAuthors()
            ])
        },
        async refreshTree(): Promise<void> {
            const { data, error } = await useApi().knowledgeBase.tree({
                contentLanguage: this.contentLanguage
            })

            if (error.value) {
                throw error.value
            }

            this.tree = data.value
        },
        async refreshAuthors(): Promise<void> {
            const { data, error } = await useApi().operator.all({ perPage: APP_MAX_PER_PAGE })

            if (error.value) {
                throw error.value
            }

            this.authors = data.value.items
        },
        async handleArticleChangesBeforeClose(confirmContext?: Omit<AppConfirmContext, 'callback'>): Promise<boolean> {
            if (this.currentArticle?.isDirty()) {
                if (!confirmContext) {
                    const { t } = useLang()

                    confirmContext = { titleText: t('knowledge-base-before-dirty-article-close-confirm-title') }
                }

                if (!await useConfirm(confirmContext)) {
                    return false
                }
            }

            return true
        },
        setArticle(
            article?: Omit<KnowledgeBaseState['currentArticle'], 'isDirty' | 'isEmpty' | 'initialTitle' | 'initialContent'>,
            replaceRoute = false
        ): void {
            if (this.currentArticle?.new) {
                this.currentArticle.parentItem.children = this.currentArticle.parentItem.children.filter(v => !v.new)
            }

            if (article) {
                this.currentArticle = Object.assign(article, {
                    new: article.new || false,
                    isDirty(this: any): boolean {
                        return (this.initialTitle !== this.title) || (this.initialContent !== this.content)
                    },
                    isEmpty(this: any): boolean {
                        return !this.title && !this.content
                    },
                    initialTitle: article.title,
                    initialContent: article.content
                })
            } else {
                this.currentArticle = undefined
            }

            navigateTo({
                replace: replaceRoute,
                query: {
                    id: this.currentArticle?._id,
                    lang: this.contentLanguage
                }
            })
        },
        async createArticle(
            parentItem: knowledgeBaseTypes.KBTreeItem | knowledgeBaseTypes.KBCategory,
            callback?: Function
        ): Promise<void> {
            if (!await this.handleArticleChangesBeforeClose()) {
                return
            }

            const userStore = useUserStore()

            this.setArticle({
                new: true,
                title: '',
                content: '',
                status: KBItemStatusEnum.Unpublished,
                parentItem,
                parent_id: parentItem._id,
                operator_id: userStore.currentOperator.id
            } as knowledgeBaseTypes.KBArticle)

            parentItem.children.push({ new: true, type: KBItemTypeEnum.Article } as any)

            callback?.()
        },
        async selectArticle(item: knowledgeBaseTypes.KBArticle, replaceRoute = false): Promise<void> {
            if (item.type !== KBItemTypeEnum.Article) {
                return
            }

            if (!await this.handleArticleChangesBeforeClose()) {
                return
            }

            const { data, error } = await useApi().knowledgeBase.oneArticle({
                id: item._id,
                contentLanguage: this.contentLanguage
            })

            if (error.value) {
                return
            }

            this.setArticle(data.value, replaceRoute)
        },
        async setFirstOrNewArticle(route: RouteLocationNormalizedLoaded = useRoute()): Promise<void> {
            const articleId = route.query.id

            const article = (articleId && this.allArticles.find(v => v._id === articleId))
                || this.allArticles[0]

            if (article) {
                await this.selectArticle(article, true)
            } else {
                await this.createArticle(this.tree)
            }
        },
        async setNextArticle(
            article: knowledgeBaseTypes.KBArticle,
            parentItem: knowledgeBaseTypes.KBTreeItem | knowledgeBaseTypes.KBCategory
        ): Promise<void> {
            const parentItemArticles = parentItem.children.filter(v => v.type === KBItemTypeEnum.Article)

            const articleIndex = article.new
                ? parentItemArticles.findIndex((v: any) => v.new)
                : parentItemArticles.findIndex(v => v._id === article._id)

            if (parentItemArticles.length > 1) {
                if (parentItemArticles[articleIndex + 1]) {
                    await this.selectArticle(parentItemArticles[articleIndex + 1] as knowledgeBaseTypes.KBArticle)
                } else {
                    await this.selectArticle(parentItemArticles[articleIndex - 1] as knowledgeBaseTypes.KBArticle)
                }
            } else {
                await this.setFirstOrNewArticle()
            }
        },
        findItemParent(
            item: knowledgeBaseTypes.KBItem
        ): knowledgeBaseTypes.KBTreeItem | knowledgeBaseTypes.KBCategory {
            const handler = (parentItem) => {
                for (const child of parentItem.children) {
                    if (child._id === item._id) {
                        return parentItem
                    }

                    if (child.type === KBItemTypeEnum.Article) {
                        continue
                    }

                    const parent = handler(child)

                    if (parent) {
                        return parent
                    }
                }
            }

            return handler(this.tree)
        },
        findItemById(id: knowledgeBaseTypes.KBItem['_id']): knowledgeBaseTypes.KBItem {
            const handler = (parentItem) => {
                if (!parentItem.children) {
                    return
                }

                if (parentItem._id === id) {
                    return parentItem
                }

                for (const child of parentItem.children) {
                    if (child._id === id) {
                        return child
                    }

                    const parent = handler(child)

                    if (parent) {
                        return parent
                    }
                }
            }

            return handler(this.tree)
        },
        findItemAncestors(item: knowledgeBaseTypes.KBItem): knowledgeBaseTypes.KBItem[] {
            const ancestors = []

            const handler = (currentItem) => {
                if (!currentItem.parent_id) {
                    return
                }

                const parentItem = this.flattenTree.find(v => v._id === currentItem.parent_id)

                if (!parentItem) {
                    return
                }

                ancestors.unshift(parentItem)

                handler(parentItem)
            }

            handler(item)

            return ancestors
        }
    }
})
