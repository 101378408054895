<script
    lang="ts"
    setup
>
    import { useKnowledgeBaseStore } from '~/stores/knowledge-base'

    const textEditorRef = ref<ReturnType<typeof defineComponent>>()

    const knowledgeBaseStore = useKnowledgeBaseStore()

    const bus = useEventBus()

    watch(() => knowledgeBaseStore.currentArticle, (value) => {
        if (value) {
            textEditorRef.value?.setContent(value.content)
        }
    })

    const saveArticle = (): void => {
        bus.emit(BUS_EVENT_KB_SAVE_ARTICLE)
    }

    const formSetDirty = (): void => {
        bus.emit(BUS_EVENT_KB_FORM_SET_DIRTY)
    }
</script>

<template>
    <div class="flex justify-center flex-1 h-[calc(100%-var(--kb-header-height))]">
        <KnowledgeBaseContentBodyWidget
            v-if="knowledgeBaseStore.contentMode === 'preview'"
            key="preview"
        />

        <AppTextEditor
            v-else
            key="text-editor"
            ref="textEditorRef"
            v-model:title="knowledgeBaseStore.currentArticle.title"
            v-model:content="knowledgeBaseStore.currentArticle.content"
            editable
            :title-placeholder="$t('text-editor-title-placeholder')"
            :content-placeholder="$t('text-editor-content-placeholder')"
            :class="[
                'max-tablet:!w-[90vw] transition-[width_var(--transition-default-duration-with-ease)]',
                (knowledgeBaseStore.viewMode === 'expanded') ? 'w-[685px]' : 'w-[375px]'
            ]"
            @update:title="formSetDirty()"
            @update:content="formSetDirty()"
            @keydown.meta.s.prevent="saveArticle()"
        />
    </div>
</template>
