<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable max-len -->
            <path
                d="M5.34003 21V2.80005H13.01C14.778 2.80005 16.1907 3.26805 17.248 4.20405C18.3227 5.12272 18.86 6.25805 18.86 7.61005C18.86 8.78872 18.444 9.77672 17.612 10.574C17.2134 10.938 16.806 11.2067 16.39 11.3801C16.9967 11.5534 17.56 11.8481 18.08 12.264C19.2067 13.2174 19.77 14.396 19.77 15.8C19.77 17.256 19.198 18.4867 18.054 19.4921C16.9274 20.4974 15.4194 21 13.53 21H5.34003ZM9.24003 17.62H13.27C14.0327 17.62 14.6307 17.4207 15.064 17.022C15.5147 16.6234 15.74 16.086 15.74 15.41C15.74 14.734 15.5147 14.1967 15.064 13.798C14.6307 13.3994 14.0327 13.2 13.27 13.2H9.24003V17.62ZM9.24003 9.82005H12.75C13.3914 9.82005 13.894 9.65538 14.258 9.32605C14.6394 8.97938 14.83 8.53738 14.83 8.00005C14.83 7.46272 14.6394 7.02938 14.258 6.70005C13.894 6.35338 13.3914 6.18005 12.75 6.18005H9.24003V9.82005Z"
                :fill="color"
            />
            <!-- eslint-enable max-len -->
        </template>
    </AppIcon>
</template>
