<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable max-len -->
            <path
                d="M8 12H16V14H8V12ZM10 20H6V4H13V9H18V12.1L20 10.1V8L14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H10V20ZM8 18H12.1L13 17.1V16H8V18ZM20.2 13C20.3 13 20.5 13.1 20.6 13.2L21.9 14.5C22.1 14.7 22.1 15.1 21.9 15.3L20.9 16.3L18.8 14.2L19.8 13.2C19.9 13.1 20 13 20.2 13ZM20.2 16.9L14.1 23H12V20.9L18.1 14.8L20.2 16.9Z"
                :fill="color"
            />
            <!-- eslint-enable max-len -->
        </template>
    </AppIcon>
</template>
