<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable max-len -->
            <path
                d="M9 21.56L11.9504 7.6757H14.843L11.8926 21.56H9ZM14.062 5.07239C13.6377 5.07239 13.2714 4.92777 12.9628 4.63851C12.6736 4.32997 12.5289 3.9443 12.5289 3.48149C12.5289 2.94154 12.7314 2.49801 13.1364 2.15091C13.5413 1.78451 13.9848 1.60132 14.4669 1.60132C14.8912 1.60132 15.2479 1.75559 15.5372 2.06413C15.8457 2.35339 16 2.72942 16 3.19223C16 3.73217 15.7975 4.18534 15.3926 4.55173C14.9876 4.89884 14.5441 5.07239 14.062 5.07239Z"
                :fill="color"
            />
            <!-- eslint-enable max-len -->
        </template>
    </AppIcon>
</template>
