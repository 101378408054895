<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 20V14H14V20H19V12H22L12 3L2 12H5V20H10Z"
                :fill="color"
            />
        </template>
    </AppIcon>
</template>
