<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <path
                d="M14 14H19V16H16V19H14V14ZM5 14H10V19H8V16H5V14ZM8 5H10V10H5V8H8V5ZM19 8V10H14V5H16V8H19Z"
                :fill="color"
            />
        </template>
    </AppIcon>
</template>
